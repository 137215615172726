import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import BreguetCategoryBtns from '../../components/preowned/BreguetCategoryBtns'

// markup
const BreguetClassique = () => {
  const data = useStaticQuery(
    graphql`
      query queryBreguetClassique {
        products: allStrapiProduct(
          filter: { brand: { eq: "Breguet" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Breguet Classique Watches for Sale'}
      canonical={'/fine-watches/other-watches-brands/breguet/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Breguet Classique watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/breguet/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-breguet-classique-header.png"
                alt="Pre-Owned Certified Used Breguet Watches Header"
                aria-label="Used Breguet Image Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">Used Breguet Classique Watches</h1>
            <h2>
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE BREGUET WATCHES AT GRAY AND
              SONS
            </h2>
            <h3>
              BUY, SELL, TRADE, CONSIGN AND REPAIR USED AND PRE-OWNED BREGUET WATCHES WITH GRAY AND
              SONS JEWELERS
            </h3>
            <p>
              <br />
              The grand history of Breguet watches trails back to 1775 when Abraham-Louis Breguet
              founded the watchmaking company in Paris, France. Breguet’s fine watchmaking skills
              attracted the likes of some of the highest members of society at the time such as
              Marie- Antoinette and her late husband, Louis XVI (the last King of France).
              <br />
              <br />
              Being that this was before the times of social media, Breguet’s fine watch crafting
              and attention to detail brought the brand global recognition; it’s no surprise that
              Breguet is crowned the grandfather of watchmaking.
              <br />
              <br />
              The Breguet Classique collection is one of the most loved among consumers. Within the
              Breguet Classique collection fall sub-collections, each offered in a variety of fine
              metals and with different functions and complications.
              <br />
              <br />
              A similarity all Breguet Classique watches hold is their integrity to the classic
              Breguet dress watch look. These timepieces carry fine details within their design such
              as fine guilloche engravings on the dials, fairly thin watch cases, and either Arabic
              or Roman numerals in the iconic Breguet marker font.
              <br />
              <br />
              <b>Buying and Selling Pre-Owned Used Breguet Classique Watches</b>
              <br />
              <br />
              One of Breguet’s collections that stays constantly in demand is the Breguet Classique.
              Whether worn as a dress watch or for everyday use, there is a Breguet Classique watch
              for any and everyone. Under the umbrella of Breguet Classique watches fall the
              following references: <br />
              <br />
              Popular Pre-Owned Used Breguet Marine Watch References and Models:
              <ul>
                <li>Pre-Owned Breguet Classique references: 7137, 5157, 7147, 7337, 7067</li>
                <li>Pre-Owned Breguet Classique Dame references: 9088, 9087, 9068, 8551</li>
                <li>Pre-Owned Breguet Classique Alarme Musicale references: 5547, 5707</li>
                <li>
                  Pre-Owned Breguet Classique Chronographe references: 5247, 5238, 5947, 5287, 5284
                </li>
              </ul>
              <br />
              <br />
              Since 1980, Gray and Sons Jewelers has been catering to clients all around the country
              with all their luxury needs. All of the services we provide are done in our very own
              store, here, in the heart of Miami, Florida. We are the top watch store in all of
              South Florida specializing in buying pre-owned Breguet watches, Selling used Breguet
              watches, repairing second-hand Breguet watches, and trading genuine Breguet
              timepieces.
              <br />
              <br />
              Gray and Sons Jewelers is the #1 all-stop shop for all Breguet Classique watch needs.
              Our store is located in South Florida in the city of Surfside across the street from
              the famous Bal Harbour Shops. Aside from buying and selling genuine pre-owned Breguet
              watches, Gray and Sons is also a certified repair center for all Swiss-made
              wristwatches. Those looking to “get cash for my used Breguet watch” should visit{' '}
              <a href="/">www.grayandsons.com</a> or
              <a href="https://sellusyourjewelry.com/"> Sell Us Your Jewelry </a> to request a free
              watch quote to sell or trade-in for one of ours!
              <br />
              <br />
              We have a brick-and-mortar store located in the heart of Miami, Fl near cities like
              Sunny Isles, Haulover, Aventura, Hollywood, Fort Lauderdale, Miami Beach, Coconut
              Grove, and Coral Gables, Key Biscayne, and many more. On our website, we have LIVE
              representatives Viktoria and Rich who are specialists in buying and selling Breguet
              Classique watches.
              <br />
              <br />
              Our <a href="/chat-with-rich">LIVE CHAT</a> feature allows clients all around the
              nation to make all inquiries about Breguet watches and possibly work out a deal in
              real-time. Gray and Sons Jewelers is the best and most reliable place to buy used
              pre-owned Breguet Classique watches. Our swiss- trained watchmakers assure every
              Breguet watch in our inventory is certified pre-owned and serviced ready for wear with
              a 1-year warranty covering the movement.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/other-watches-brands/breguet/">
                <button>SHOP BREGUET WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK BREGUET WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <BreguetCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/drvX30qLcgg'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default BreguetClassique
